var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "parent-container" }, [
    _vm.hasSysAdminPermission ||
    (_vm.currentCompany && _vm.currentCompany.licenseType)
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.dataLoading,
                expression: "dataLoading",
              },
            ],
            staticClass: "manager-container",
          },
          [
            _vm.hasSysAdminPermission
              ? _c("div", { staticClass: "left" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _c("base-block-title", {
                        attrs: {
                          title: _vm.$t("company"),
                          "sub-title": _vm.filteredCompanies.length,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "search-bar" },
                    [
                      _c("el-input", {
                        staticClass: "round-input",
                        attrs: { placeholder: "Search Company Name" },
                        model: {
                          value: _vm.searchCompanyInput,
                          callback: function ($$v) {
                            _vm.searchCompanyInput = $$v
                          },
                          expression: "searchCompanyInput",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "content" },
                    _vm._l(_vm.filteredCompanies, function (item) {
                      return _c("company-item", {
                        key: item.id,
                        attrs: {
                          company: item,
                          active:
                            _vm.selectedCompany &&
                            item.id === _vm.selectedCompany.id,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleAdminSelectCompany(item)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "div",
                  { staticClass: "div-main-title" },
                  [
                    _c("h1", [_vm._v("BDN License Management")]),
                    _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
                      ? _c(
                          "el-button",
                          {
                            attrs: { round: "", type: "primary" },
                            on: { click: _vm.handleOpenAuthorizationDialog },
                          },
                          [_vm._v("Authorize New Company")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.authorizedCompanies,
                      border: "",
                      "header-cell-style": _vm.HEADER_CELL_STYLE,
                      "cell-style": _vm.CELL_STYLE,
                      width: "50%",
                      height: "300px",
                    },
                  },
                  [
                    _c("el-table-column", { attrs: { type: "index" } }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Company Name",
                        width: "280px",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(_vm._s(scope.row.companyName)),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2445336401
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "Business Incorporation No.",
                        width: "240px",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.businessIncorporationNumber
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4153061181
                      ),
                    }),
                    _vm.hasPermission(_vm.$permissionFunctionCode.DELETE)
                      ? _c("el-table-column", {
                          attrs: { width: "60px", "show-overflow-tooltip": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-button", {
                                      attrs: {
                                        type: "danger",
                                        icon: "el-icon-delete",
                                        circle: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deauthorizeCompanyConfirmation(
                                            scope.row
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1293993725
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.hasSysAdminPermission
                  ? [
                      _c("h1", [_vm._v(" My BDN Organization")]),
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.authorizedMeCompanies,
                            border: "",
                            "header-cell-style": _vm.HEADER_CELL_STYLE,
                            "cell-style": _vm.CELL_STYLE,
                            width: "50%",
                          },
                        },
                        [
                          _c("el-table-column", { attrs: { type: "index" } }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Company Name",
                              width: "280px",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(_vm._s(scope.row.companyName)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2445336401
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Business Incorporation No.",
                              width: "240px",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row
                                              .businessIncorporationNumber
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4153061181
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "el-dialog",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.authorizeLoading,
                    expression: "authorizeLoading",
                  },
                ],
                staticClass: "el-dialog-authorize-company",
                attrs: {
                  title: "Authorize New Company",
                  visible: _vm.dialogAuthorizeCompany,
                  width: "35%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogAuthorizeCompany = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "authorizationForm",
                    attrs: {
                      model: _vm.authorizationForm,
                      rules: _vm.rules,
                      "label-position": "top",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Company Name",
                              prop: "counterpartyOrganizationId",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  placeholder: "Select",
                                },
                                model: {
                                  value:
                                    _vm.authorizationForm
                                      .counterpartyOrganizationId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.authorizationForm,
                                      "counterpartyOrganizationId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "authorizationForm.counterpartyOrganizationId",
                                },
                              },
                              _vm._l(_vm.companyOptions, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.companyName,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { attrs: { slot: "footer" }, slot: "footer" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { round: "", type: "primary" },
                        on: { click: _vm.authorizeCompany },
                      },
                      [_vm._v(" Confirm ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { outline: "", round: "" },
                        on: { click: _vm.handleCloseAuthorizationDialog },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c("div", [_c("h1", [_vm._v("You are not a physical supplier")])]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }