<template>
  <div class="parent-container">
    <div v-if="hasSysAdminPermission || (currentCompany && currentCompany.licenseType)" class="manager-container" v-loading="dataLoading">
      <div class="left" v-if="hasSysAdminPermission">
        <div class="title">
          <base-block-title
            :title="$t('company')"
            :sub-title="filteredCompanies.length"
          >
          </base-block-title>
        </div>
        <div class="search-bar">
          <el-input
            class="round-input"
            placeholder="Search Company Name"
            v-model="searchCompanyInput"
          >
          </el-input>
        </div>
        <div class="content">
          <company-item
            v-for="item in filteredCompanies"
            :key="item.id"
            :company="item"
            :active="selectedCompany && item.id === selectedCompany.id"
            @click.native="handleAdminSelectCompany(item)"
          >
          </company-item>
        </div>
      </div>
      <div class="right">
        <div class="div-main-title">
          <h1>BDN License Management</h1>
          <el-button v-if="hasPermission($permissionFunctionCode.ADD)" round type="primary" @click="handleOpenAuthorizationDialog">Authorize New Company</el-button>
        </div>
        <el-table
          :data="authorizedCompanies"
          border
          :header-cell-style="HEADER_CELL_STYLE"
          :cell-style="CELL_STYLE"
          width="50%"
          height="300px"
        >
          <el-table-column type="index"> </el-table-column>
          <el-table-column
            label="Company Name"
            width="280px"
            show-overflow-tooltip
          >
            <template v-slot="scope">
              <div>{{ scope.row.companyName }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="Business Incorporation No."
            width="240px"
            show-overflow-tooltip
          >
            <template v-slot="scope">
              <div>{{ scope.row.businessIncorporationNumber }}</div>
            </template>
          </el-table-column>
          <el-table-column  v-if="hasPermission($permissionFunctionCode.DELETE)" width="60px" show-overflow-tooltip>
            <template v-slot="scope">
              <el-button
                @click="deauthorizeCompanyConfirmation(scope.row)"
                type="danger"
                icon="el-icon-delete"
                circle
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <template v-if="hasSysAdminPermission">
        <h1> My BDN Organization</h1>
        <el-table
          :data="authorizedMeCompanies"
          border
          :header-cell-style="HEADER_CELL_STYLE"
          :cell-style="CELL_STYLE"
          width="50%"
        >
          <el-table-column type="index"> </el-table-column>
          <el-table-column
            label="Company Name"
            width="280px"
            show-overflow-tooltip
          >
            <template v-slot="scope">
              <div>{{ scope.row.companyName }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="Business Incorporation No."
            width="240px"
            show-overflow-tooltip
          >
            <template v-slot="scope">
              <div>{{ scope.row.businessIncorporationNumber }}</div>
            </template>
          </el-table-column>
        </el-table>
      </template>
      </div>
      <el-dialog
        v-loading="authorizeLoading"
        class="el-dialog-authorize-company"
        title="Authorize New Company"
        :visible.sync="dialogAuthorizeCompany"
        width="35%"
      >
        <el-form
          :model="authorizationForm"
          :rules="rules"
          ref="authorizationForm"
          label-position="top"
        >
          <div>
            <el-form-item
              label="Company Name"
              prop="counterpartyOrganizationId"
            >
              <el-select
                v-model="authorizationForm.counterpartyOrganizationId"
                filterable
                placeholder="Select"
              >
                <el-option
                  v-for="item in companyOptions"
                  :key="item.id"
                  :label="item.companyName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer">
          <el-button round type="primary" @click="authorizeCompany">
            Confirm
          </el-button>
          <el-button outline round @click="handleCloseAuthorizationDialog">
            Cancel
          </el-button>
        </div>
      </el-dialog>
    </div>
    <div v-else >
      <h1>You are not a physical supplier</h1>
    </div>
  </div>
</template>

<script>
import { HEADER_CELL_STYLE, CELL_STYLE } from "@/constants.js";
import { mapState } from "vuex";
import { sortByAlphabetical } from "@/utils";

export default {
  name: "bdnLicenseManagement",
  data() {
    const rules = {
      counterpartyOrganizationId: [
        {
          required: true,
          message: "This field is required",
          trigger: ["blur", "change"],
        },
      ],
    };
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      rules,
      authorizedCompanies: [],
      authorizedMeCompanies: [],
      companies: [],
      authorizationForm: {
        counterpartyOrganizationId: null,
        bdnOrganizationId: null,
      },
      dialogAuthorizeCompany: false,
      dataLoading: false,
      authorizeLoading: false,
      selectedCompany: null,
      searchCompanyInput: null,
    };
  },
  computed: {
    ...mapState(["currentCompany", "permissions"]),
    authorizedCompanyIds() {
      return this.authorizedCompanies.map((a) => a.id);
    },
    authorizedMeCompanyIds() {
      return this.authorizedMeCompanies.map((a) => a.id);
    },
    companyOptions() {
      if (!this.selectedCompany) return [];
      return this.companies.filter(
        (c) =>
          c.id !== this.selectedCompany.id &&
          !this.authorizedCompanyIds.includes(c.id)
      );
    },
    hasSysAdminPermission() {
      return this.permissions.hasSysAdminPermission;
    },
    filteredCompanies() {
      if (!this.searchCompanyInput) return this.companies.filter(c => c.licenseType);
      return this.companies.filter(c => c.licenseType && c.companyName.toLowerCase().includes(this.searchCompanyInput.toLowerCase())
      );
    },
  },
  mounted() {
    this.dataLoading = true;
    this.getOnlineCompanies().then( async() => {
      this.selectedCompany = this.hasSysAdminPermission ? this.companies.find(() => true): this.currentCompany;
      try {
        await this.getAuthorizedCompanies(this.selectedCompany.id)
        if (this.hasSysAdminPermission) {
          await this.getAuthorizedMeCompanies(this.selectedCompany.id)
        }
      } catch (error) {

      } finally {
        this.dataLoading = false
      }

    });
  },
  methods: {
    sortByAlphabetical,
    async getAuthorizedCompanies(companyId) {
      const res = await this.$request.get({
        url: `${this.$apis.bdnLicenseAuthorization}/organizations/${companyId}/authorized_organizations`,
      });

      if (res.code === 1000) this.authorizedCompanies = res.data;
    },
    async getAuthorizedMeCompanies(companyId) {
      const res = await this.$request.get({
        url: `${this.$apis.bdnLicenseAuthorization}/organizations/${companyId}/authorized_bdn_organizations`,
      });

      if (res.code === 1000) this.authorizedMeCompanies = res.data;
    },
    async getOnlineCompanies() {
      const res = await this.$request.get({
        url: `${this.$apis.sharedCompanyBaseUrl}?pageNumber=1&pageSize=9999&offline=false`
      });
      if (res?.code === 1000) {
        const filtered =
          res.data?.records.filter(
            (c) => c.companyName && c.companyName !== "noCompany"
          ) ?? [];
        this.companies = this.sortByAlphabetical(filtered, "companyName");
      }
    },
    handleOpenAuthorizationDialog() {
      this.dialogAuthorizeCompany = true;
    },
    handleCloseAuthorizationDialog() {
      this.dialogAuthorizeCompany = false;
      this.resetForm();
    },
    authorizeCompany() {
      this.$refs.authorizationForm.validate(async (valid) => {
        if (!valid) return;

        this.authorizeLoading = true;
        const res = await this.$request.post({
          url: this.$apis.bdnLicenseAuthorizationAuthorize,
          data: {
            ...this.authorizationForm,
            bdnOrganizationId: this.selectedCompany.id,
          },
        });

        this.authorizeLoading = false;
        if (res.code !== 1000) {
          this.$message({
            type: "error",
            message: "Error authorizing company",
          });
          return;
        }

        this.resetForm();
        this.getAuthorizedCompanies(this.selectedCompany.id);
        this.dialogAuthorizeCompany = false;
      });
    },
    resetForm() {
      this.authorizationForm = {
        counterpartyOrganizationId: null,
        bdnOrganizationId: null,
      };

      this.$refs.authorizationForm.resetFields();
    },
    async deauthorizeCompany(companyId) {
      this.dataLoading = true;
      const res = await this.$request.post({
        url: this.$apis.bdnLicenseAuthorizationDeauthorize,
        data: {
          counterpartyOrganizationId: companyId,
          bdnOrganizationId: this.selectedCompany.id,
        },
      });

      if (res.code !== 1000) {
        this.dataLoading = false;
        return;
      }

      await this.getAuthorizedCompanies(this.selectedCompany.id);
      this.dataLoading = false;
    },
    async deauthorizeCompanyConfirmation(company) {
      this.$confirm(
        `The company (${company.companyName}) removed will no longer be able to use your company’s BDN. \nConfirm to remove? `,
        "Remove Authorized Company",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          await this.deauthorizeCompany(company.id);
          this.$message({
            type: "success",
            message: "Remove completed",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Remove canceled",
          });
        });
    },
    async handleAdminSelectCompany(company) {
      this.selectedCompany = company;
      await this.getAuthorizedCompanies(this.selectedCompany.id);
      if (this.hasSysAdminPermission) {
        await this.getAuthorizedMeCompanies(this.selectedCompany.id);
      }
    },
    handleAdminSearchCompany() {
      const key = this.searchCompanyKey;
      this.getCompanies().then(() => {
        if (this.isValidString(key) && this.companyList?.length > 0) {
          const companyListSearched = this.companyList.filter((d) =>
            d.companyName
              .toLowerCase()
              .includes(this.searchCompanyKey.toLowerCase())
          );
          this.companyList = companyListSearched;
          this.toggleSearchCompanyIcon = !this.toggleSearchCompanyIcon;
        } else {
          this.toggleSearchCompanyIcon = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.manager-container {
  display: flex;
  height: 100%;
  width: 100%;

  .left {
    box-sizing: border-box;
    height: 100%;
    width: 310px;
    margin-right: 5px;
    padding: 10px 0px 10px 10px;
    border: 1px solid #f2f6fc;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .content {
      margin-top: 10px;
      height: calc(90% - 50px);
      overflow-y: auto;
    }
  }
  .right {
    padding-left: 10px;
    box-sizing: border-box;
    border-left: 1px solid $primary-color-border;
    overflow-y: auto;
  }
}
.div-main-title {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  & > h1 {
    color: $primary-color-font;
    text-align: left;
    margin: 0;
    font-size: 20px;
    min-width: 180px;
  }
  .el-button {
    margin-left: auto;
  }
}
.el-dialog-authorize-company {
  /deep/.el-dialog__title {
    font-size: 22px;
    font-weight: bold;
    color: #4c565c;
  }

  /deep/.el-dialog__footer {
    display: flex;
    justify-content: center;
  }
}

.parent-container {
  height: 100%;
}
</style>
